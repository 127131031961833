import logo from './logo.svg';
import imgNutIcon from './img/nut.png'
import styled, { createGlobalStyle } from 'styled-components';
import Modal from 'react-modal';
import { useState } from 'react';

const PADDING = "20px";

// Global styles with Google Font
const GlobalStyle = createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
  }

  body {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    background-color: black;
    color: white;
    margin: 0;
    padding: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1, h2, h3 {
    font-family: 'Lexend', sans-serif;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  a {
  color: yellow
  }
`;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  box-sizing: border-box;
  border: 5px solid white;
  border-radius: 20px;
  padding: 0;

  background-image: url(${imgNutIcon});
  background-repeat: repeat;
  background-position: 0 0;
  background-size: 400px 400px;
  animation: scrollBackground 8s linear infinite;

  @keyframes scrollBackground {
    from {
      background-position: 0 0;
    }
    to {
      background-position: 800px 800px;
    }
  }
`;

const ContainerCenter = styled.div`
  user-select: none;
    display:none;
`;

const ContentManifesto = styled.div`
  p {
    margin-bottom: 30px;
  }

  h2 {
    margin-bottom: 20px;
    border-bottom: 1px dotted white;
  }

  h3 {
    margin-bottom: 5px;
  }
`;

const ContainerNuts = styled.div`
  user-select: none;
  background-color: rgba(255,255,255,0.7);
  padding: 10px;
  border-radius: 10px;



  border: 3px solid white;

`;

const ContainerMarketCap = styled.div`
  text-align: right;
`;

const ContainerToken = styled.div`
background-color: black;
padding: 10px;
border-radius: 10px;

  position: absolute;
  bottom: ${PADDING};
  left: ${PADDING};
  max-width: 40vw;
  word-wrap: break-word;
  overflow: hidden;

  h1 {
    font-size: 3em;
  }
`;

const ContainerDesc = styled.div`

  position: absolute;
  bottom: ${PADDING};
  right: ${PADDING};
  text-align: right;
  max-width: 40vw;

  h2 {
    margin-bottom: 5px;
  }
`;

const NutStyle = styled.span`
  img {
    width: 4vw;
  filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.4));
  }
`;

function Nut() {
  return (
    <NutStyle>
      <img src ={imgNutIcon}/>
    </NutStyle>
  )
}

function App() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <GlobalStyle/>
      <AppContainer>
        <ContainerCenter>
          <ContainerNuts>
            <Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><br/>
            <Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><br/>
            <Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><br/>
            <Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><br/>
            <Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><br/>
            <Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><br/>
            <Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><Nut/><br/>
          </ContainerNuts>

          <ContainerMarketCap>
            <h3>5% / $503,320</h3>
          </ContainerMarketCap>

          <br/><br/><br/>
        </ContainerCenter>

        <ContainerToken>
          <h1>$NUTILIO</h1>
          <p>CvSX1yjorzsYePXacR5bEK7f2U8cb67EXRMb8Kfzpump</p>
        </ContainerToken>

        <ContainerDesc>
          <h2>there is no nut, i love u</h2>
          <p>the official "No Nut November" RWA (resisting wank asset)  [ <a href="#" onClick={openModal}>read the manifesto</a> ]</p>
        </ContainerDesc>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Manifesto Modal"
          style={{
            content: {
              color: 'white',
              background: 'black',
              borderRadius: '10px',
              padding: '30px',
              maxWidth: '400px',
              margin: 'auto'
            }
          }}
        >
          <ContentManifesto>
            <h2>$NUTILIO MANIFESTO</h2>
            <p>No Nut November (NNN) is more than a challenge—it's a declaration of self-mastery and focus, much like the $NUTILIO asset itself. By abstaining from self-indulgence, we take on a path that enhances discipline, hones mental clarity, and strengthens willpower. This month-long commitment aligns with the principles of e/acc (effective accelerationism) and the pursuit of the GIGA mindset, encouraging us to leverage restraint and intention toward personal and collective growth.</p>

            <h3>1. Discipline as a Superpower</h3>
            <p>In a world driven by instant gratification, the practice of restraint becomes revolutionary. E/acc seeks to accelerate human potential, and discipline is the foundation of this journey. No Nut November serves as a reset, training us to channel our impulses constructively, aligning with e/acc's goals of optimized productivity and growth. Self-control isn't just a virtue; it's a tool for propelling the self toward higher aspirations.</p>
            <h3>2. Focused Energy for the Giga Mindset</h3>
            <p>The “Giga” mindset—one of dedication, resilience, and high-level focus—thrives on channeling energy where it matters. By abstaining, we harness energy that would otherwise be dispersed, reinvesting it into pursuits that enhance our strength, intellect, and ambition. This redirection embodies the Giga mindset, transforming a simple action into a powerful statement about self-mastery and priority.</p>

            <h3>3. Embodying an Optimized Future</h3>
            <p>At its core, e/acc is about embracing pathways that optimize human potential and accelerate society toward its peak. No Nut November is a microcosm of this philosophy—a small but potent practice that cultivates discipline, clarity, and power. Through it, we practice a more intentional existence, focusing our efforts on actions that drive forward both personal and collective evolution.</p>

            <h3>4. Beyond the Challenge: A Lifestyle</h3>
            <p>NNN isn't just about a month of abstinence—it's about forging habits that encourage self-control and a forward-thinking mindset. As we engage with these practices, we align ourselves with The Trajectory: a commitment to personal optimization that scales to societal transformation. The goal is to cultivate a lifestyle where purpose and discipline intersect with ambition.</p>
          </ContentManifesto> 
        </Modal>

      </AppContainer>
    </>
  );
}

export default App;
